/**
 * @prettier
 */
import React from 'react';
import {graphql} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import get from 'lodash/get';
import Container from '../components/Container';
import HeroBanner from '../components/HeroBanner';
import Page from '../components/Page';
import * as UI from '../components/UI/styles';

const ServiceDetailTemplate = ({data}) => {
    const service = get(data, 'service');

    return (
        <Page>
            <HeroBanner title={get(service, 'title')} />

            <UI.ResponsiveSpacer size="s" sizeAtMobile="l" />

            <Container>
                <UI.LayoutContainer css={UI.flexAtMobile}>
                    <UI.LayoutItem sizeAtTablet={9 / 12}>
                        <ReactMarkdown source={get(service, 'content.content')} />
                    </UI.LayoutItem>
                </UI.LayoutContainer>
            </Container>
        </Page>
    );
};

export const query = graphql`
    query($slug: String!) {
        service: contentfulPage(slug: {eq: $slug}, pageType: {eq: "Service"}) {
            title
            slug
            tags
            content {
                content
            }
            featuredPhoto {
                large: fixed(width: 1100) {
                    width
                    height
                    src
                }
                small: fixed(width: 600) {
                    width
                    height
                    src
                }
            }
            publishDate(formatString: "MMMM Do, YYYY")
        }
    }
`;

export default ServiceDetailTemplate;
